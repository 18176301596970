import { API } from "aws-amplify"
import { navigate } from "gatsby"

export default async function submitInfo(values) {
  let apiName = "bizhelpMiamiFundingFormResponses"
  let path = "/formResponses"
  let myInit = {
    body: {
      values,
    },
  }
  var response = await API.post(apiName, path, myInit)
  if (response.success) {
    navigate("/success/", {
      state: {
        formValues: values,
      },
    })
  }
}
