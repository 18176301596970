export  const LoanAmounts = {
    loanAmount: [
      {
        "amount": "<$350K"
      },
      {
        "amount": "$351K to $500K"
      },
      {
        "amount": "$501k to $1Million"
      },
      {
        "amount": ">$1 Million"
      } 
    ]
  }
  
  export default LoanAmounts