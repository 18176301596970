import React from "react"
import "./funding.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Form from "../components/CompanyInfoForm/form"

const FundingResources = () => {
  return (
    <Layout>
      <SEO title="Funding Resources" />
      <div className="container primary-bg">
        <section>
          <div>
          <h1 className="topText">
            We’re here to help you navigate the Paycheck Protection Loan application process.
          </h1>
          <text className="secondTopText">
            The information below will help us direct you to the most relevant resources for your business. Our goal is to stay connected with Miami-Dade’s small businesses, sole proprietors and freelancers, supporting you during these challenging times and helping to safeguard our local economy. 
          </text>
          </div>
          <hr></hr>
        </section>
        <section>
          <div>
            <Form />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default FundingResources
