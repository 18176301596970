import { useState, useEffect } from "react"

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    ownerName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    industry: [],
    loanAmount: "",
    referralSource: "",
    numOfEmployees: "",
    bank: "",
    bankName: "",
    contactOptIn: false,
  })
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    if (values.bank) {
      values.bankName = "approvedBankSelected"
    }

    if (values.bank === "" && values.bankName) {
      values.bank = null
    }

    setErrors(validate(values))
    setIsSubmitting(true)
  }

  const handleChange = event => {
    event.persist()
    
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  
  }

 

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback()
    }
  }, [errors])

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  }
}

export default useForm
