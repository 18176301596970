export const NumOfEmployees = {
  numOfEmployees: [
    {
      number: "1 (sole proprietor)"
    },
    {
      number: "<10",
    },
    {
      number: "11-50",
    },
    {
      number: "51-100",
    },
    {
      number: "101-500",
    }, 
  ],
}

export default NumOfEmployees
