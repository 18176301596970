export default function formValidation(values) {
  let errors = {}

  if (!values.email) {
    errors.email = "Email is required"
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid"
  }

  if (!values.ownerName) {
    errors.ownerName = "First and last names are required"
  } else if (values.ownerName.length < 1) {
    errors.ownerName ="First and last names are required"
  }

  let phone = values.phoneNumber
  phone = phone.replace(/[^\d]/g, "");
  if (!values.phoneNumber) {
    errors.phoneNumber = "Phone Number is required"
  } else if (phone.length != 10) {
    errors.phoneNumber = "phone number is invalid"
  }

  if (!values.companyName) {
    errors.companyName = "Company name or none is required"
  }

  if (values.industry.length == 0) {
    errors.industry = "Please select an industry"
  }

  if(values.industry.length > 2) {
    errors.industry = "Please only select max of 2 industries"
  }

  if (!values.loanAmount) {
    errors.loanAmount = "Please select a loan amount"
  }

  if (!values.referralSource) {
    errors.referralSource = "Please select a referral source"
  }

  if (!values.numOfEmployees) {
    errors.numOfEmployees = "Please select a number of employees"
  }

  if (!values.bank && !values.bankName) {
    errors.bank = "Please select a bank or enter a bank name"
  }

  return errors
}
