import React, { useState, useContext, useEffect } from "react"
import { Industries } from "../../data/industry"
import { LoanAmounts } from "../../data/loanAmounts"
import useCustomForm from "./useForm"
import "./form.css"
import { ReferralSource } from "../../data/referralSource"
import { NumOfEmployees } from "../../data/numOfEmployees"
import BankSelectionList from "../../components/BankSelectionList"
import validate from "./formValidation"
import submitInfo from "./submitInfo"
import Multiselct from "react-select"
import bankList from "../../data/banks"
import { useCookies } from "react-cookie"
import { navigate } from "gatsby"

const Form = () => {
  const { values, handleSubmit, handleChange, errors } = useCustomForm(
    submit,
    validate
  )
  const [showText, setShowText] = useState(false)
  const [industryList, setIndustryList] = React.useState([])
  const [cookies, setCookie] = useCookies(["bizhelpMiami", "bizHelpMiamiConsent"])
 
  useEffect(() => {
    if(cookies.bizhelpMiami) {
      navigate("/success/", {
        state: {
          formValues: cookies.bizhelpMiami,
        },
      })
    }
  })

  async function submit() {
    if (cookies.bizHelpMiamiConsent) {
      setCookie("bizhelpMiami", values, { path: "/" })
    }
    submitInfo(values)
  }

  function addSelectedIndustries(selectedOption) {
    setIndustryList({ selectedOption })
    values.industry = selectedOption
  }

  return (
    <main class="main">
      <form onSubmit={handleSubmit}>
        <figure class="figure1">
          <div className="form-group">
            <label for="ownerName">Full Name</label>
            <input
              type="text"
              name="ownerName"
              id="ownerName"
              aria-describedby="Owner Name"
              className="form-control form-input"
              placeholder="Full Name"
              onChange={handleChange}
              value={values.ownerName}
            />
            <small id="ownerNameHelp" className="form-text text-muted">
              {errors.ownerName && <p className="error">{errors.ownerName}</p>}
            </small>
          </div>
        </figure>
        <figure class="figure2">
          <div className="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              className="form-control form-input"
              onChange={handleChange}
              value={values.email}
            />
            <small id="emailHelp" className="form-text text-muted">
              {errors.email && <p className="error">{errors.email}</p>}
            </small>
          </div>
        </figure>
        <figure class="figure3">
          <div className="form-group">
            <label for="phoneNumber">Phone Number</label>
            <input
              type="tel"
              name="phoneNumber"
              id="phoneNumber"
              placeholder="Phone Number"
              className="form-control form-input"
              onChange={handleChange}
              value={values.phoneNumber}
            />
            <small id="phoneNumberHelp" className="form-text text-muted">
              {errors.phoneNumber && (
                <p className="error">{errors.phoneNumber}</p>
              )}
            </small>
          </div>
        </figure>
        <figure class="figure4">
          <div className="form-group">
            <label for="companyName">
              Company Name (If Individual, enter "None")
            </label>
            <input
              type="text"
              name="companyName"
              id="companyName"
              className="form-control form-input"
              placeholder="Company"
              onChange={handleChange}
              value={values.companyName}
            />
            <small id="companyNameHelp" className="form-text text-muted">
              {errors.companyName && (
                <p className="error">{errors.companyName}</p>
              )}
            </small>
          </div>
        </figure>
        <figure class="figure5">
          <div className="form-group">
            <label for="industry">
              Select Your Industry (You can choose up to 2)
            </label>
            <div>
              <Multiselct
                isMulti
                value={industryList.label}
                onChange={event => addSelectedIndustries(event)}
                options={Industries.industries}
              ></Multiselct>
            </div>
            <small id="industryHelp" className="form-text text-muted">
              {errors.industry && <p className="error">{errors.industry}</p>}
            </small>
          </div>
        </figure>
        <figure class="figure8">
          <div className="form-group">
            <label for="numOfEmployees">Select Number of Employees</label>
            <select
              id="numOfEmployees"
              name="numOfEmployees"
              onChange={handleChange}
              values={values.numOfEmployees}
              className="form-control form-input"
              defaultValue="placeholder"
            >
              <option value="placeholder" disabled hidden>
                Select number of Employees
              </option>
              {NumOfEmployees.numOfEmployees &&
                NumOfEmployees.numOfEmployees.map((e, key) => (
                  <option key={key} value={e.number}>
                    {e.number}
                  </option>
                ))}
            </select>
            <small id="numOfEmployeesHelp" className="form-text text-muted">
              {errors.numOfEmployees && (
                <p className="error">{errors.numOfEmployees}</p>
              )}
            </small>
          </div>
        </figure>
        <figure class="figure7">
          <div className="form-group">
            <label for="referralSource">How Did You Hear About Us</label>
            <select
              id="referralSource"
              name="referralSource"
              onChange={handleChange}
              values={values.referralSource}
              className="form-control form-input"
              defaultValue="placeholder"
            >
              <option value="placeholder" disabled hidden>
                How did you hear about us?
              </option>
              {ReferralSource.responses.map((e, index) => (
                <option key={index} value={e.response}>
                  {e.response}
                </option>
              ))}
            </select>
            <small id="referralSourceHelp" className="form-text text-muted">
              {errors.referralSource && (
                <p className="error">{errors.referralSource}</p>
              )}
            </small>
          </div>
        </figure>
        <figure class="figure6">
          <div className="form-group">
            <label for="loanAmount">Select Your Loan Amount</label>
            <select
              id="loanAmount"
              name="loanAmount"
              onChange={handleChange}
              values={values.loanAmount}
              className="form-control form-input"
              defaultValue="placeholder"
            >
              <option value="placeholder" disabled hidden>
                Select your loan amount
              </option>
              {LoanAmounts.loanAmount &&
                LoanAmounts.loanAmount.map((e, key) => (
                  <option key={key} value={e.amount}>
                    {e.amount}
                  </option>
                ))}
            </select>
            <small id="loanAmountHelp" className="form-text text-muted">
              {errors.loanAmount && (
                <p className="error">{errors.loanAmount}</p>
              )}
            </small>
          </div>
        </figure>
        <figure class="figure8">
          <div className="form-group">
            <label for="bank">Select Your Bank</label>
            <select
              className="form-control form-input"
              name="bank"
              id="bank"
              onChange={handleChange}
              values={values.bank}
              defaultValue="placeholder"
            >
              <option value="placeholder" disabled hidden>
                Select your Bank
              </option>
              {bankList.banks &&
                bankList.banks
                  .sort((a, b) => (a.Bank > b.Bank ? 1 : -1))
                  .map((e, key) => (
                    <option key={key} value={e.Bank}>
                      {e.Bank}
                    </option>
                  ))}
            </select>
            <small id="bankSelectionHelp" className="form-text text-muted">
              {errors.bank && <p className="error">{errors.bank}</p>}
            </small>
          </div>
        </figure>
        <figure class="figure">
          <span class="linkButton" onClick={() => setShowText(!showText)}>
            I don't see my bank in this list
          </span>
        </figure>
        <figure>
          <div>
            {showText && (
              <div className="form-group">
                <label for="bankName">Enter Your Bank Name</label>
                <input
                  type="text"
                  name="bankName"
                  id="bankName"
                  className="form-control form-input"
                  placeholder="Bank Name"
                  onChange={handleChange}
                  values={values.bankName}
                />
                <small id="bankNameHelp" className="form-text text-muted">
                  {errors.bank && <p className="error">{errors.bank}</p>}
                </small>
              </div>
            )}
          </div>
        </figure>
        <figure class="figure9">
          <div className="form-group">
            <div class="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="contactOptIn"
                id="contactOptIn"
                values={values.contactOptIn}
                onChange={handleChange}
              />
              <label className="form-check-label" for="contactOptIn">
                The Beacon Council has my permission to contact me in the future
                with updates
              </label>
            </div>
          </div>
        </figure>
        <figure>
          <button className="button btn-primary primary-cta" type="submit">
            Submit Registration for BizHelp.Miami
          </button>
        </figure>
      </form>
    </main>
  )
}

export default Form
