export const Industries = {
  industries: [
    {
      value: "Agriculture", 
      label: "Agriculture"
    },
    {
      value: "Aviation",
      label: "Aviation"
    },
    {
      value: "Banking & Finance",
      label: "Banking & Finance",
    },
    {
      value: "Creative",
      label: "Creative"
    },
    {
      value: "Construction",
      label: "Construction"
    },
    {
      value: "Hospitality & Tourism",
      label: "Hospitality & Tourism",
    },
    {
      value: "Life Science & Healthcare",
      label: "Life Science & Healthcare",
    },
    {
      value: "Real Estate",
      label: "Real Estate"
    },
    {
      value: "Retail",
      label: "Retail"

    },
    {
      value: "Technology",
      label: "Technology"
    },
    {
      value: "Telecommunications",
      label: "Telecommunications"
    },
    {
      value: "Trade & Logistics",
      label: "Trade & Logistics"
    },
    {
      value: "Non-Profit",
      label: "Non-Profit"
    },
    {
      value: "Other",
      label: "Other"
    },
  ],
}

export default Industries
