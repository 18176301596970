export const ReferralSource = {
  responses: [
    {
      response: "The Beacon Council",
    },
    {
      response: "County/City Government",
    },
    {
      response: "SBA/SBDC",
    },
    {
      response: "Chamber/Other organization",
    },
    {
      response: "Friend/Colleague",
    },
    {
      response: "TV",
    },
    {
      response: "Radio",
    },
    {
      response: "Newspaper",
    },
    {
      response: "Online",
    },
    {
      response: "Other",
    },
  ],
}

export default ReferralSource
